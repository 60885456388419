import { Injectable, OnDestroy, inject } from '@angular/core';
import ShortUniqueId from 'short-unique-id';

// firestore functions
// DocumentData, addDoc, updateDoc, deleteDoc, DocumentReference,
import {
  Firestore,
  doc,
  updateDoc,
  collection,
  query,
  where,
  onSnapshot,
  or,
} from '@angular/fire/firestore';
import { Functions } from '@angular/fire/functions';
import { Observable, Subject } from 'rxjs';
import { BuyContract, SellContract } from '../interfaces/orders-interface';
import { IItem, UtilitiesModule } from '@coreui/angular-pro';
import { result } from 'lodash-es';
// export interface ContractAbstract  {
//   contractID: string;
//   commodityCode: string;
//   contractQuantity: number;
//   contractPrice: number;
//   status : string;
//   deliveryDate: Date;
// }

@Injectable({
  providedIn: 'root',
})
export class ContractsService {
  private firestore: Firestore = inject(Firestore); // inject Cloud Firestore
  private functions = inject(Functions); // inject Cloud Functions

  buyContracts: BuyContract[] = [];
  buyContractsWatcher: any;
  buyContractsObserver: any;
  sellContracts: SellContract[] = [];
  sellContractsWatcher: any;
  sellContractsObserver: any;
  allContracts: any = {
    new: [],
    pending: [],
    funding: [],
    receiving: [],
    payment: [],
    shipping: [],
    closed: [],
  };
  abstracts: IItem[] = [];
  showContracts: boolean = true;

  /** Contract statii
   * # - queue - status - description
   * 0 - open - open - for acceptance, not yet a fully signed contract
   * 1 - pending - pending - contracted, but delivery date is still far away
   * 2 - funding - funding - buyer has to pre-fund the contract prior to delivery of goods
   * 3 - receiving - receiving - order has been received by stockpoint
   * 4 - payment - payment - transfer of payment to seller
   * 5 - shipping - shipping - lot is ready to be shipped to buyer
   * 6 - closed - fulfilled - lot has been shipped to buyer
   */

  constructor() {}

  /**
   * blankBuyContract creates a blankBuyContract
   * useful when creating the variable but not yet defining it
   * this is a utility function for ESLint compliance
   * @returns a blank Buy contract
   */

  blankBuyContract(): BuyContract {
    var blank: BuyContract = {
      contractID: '',
      orderID: '',
      buyerID: '',
      buyerBusinessID: '',
      sellerID: '',
      sellerBusinessID: '',
      commodity: {
        commodityID: '',
        name: '',
        code: '',
        description: '',
        specification: '',
        uom: '',
        testingOptions: [],
        acceptanceOptions: [],
        sortOrder: 0,
      },
      contractQuantity: 0,
      contractPrice: 0,
      contractType: 'buy',
      deliveryDate: new Date(),
      stockpoint: {
        stockpointID: '',
        name: '',
        description: '',
        shortname: '',
        phone: '',
        address: {
          street: '',
          city: '',
          barangay: '',
          province: '',
          zipcode: '',
        },
        coords: {
          longitude: 14.653493,
          latitude: 121.046258,
        },
        services: [],
        commodities: [],
      },
      testingSpecs: [],
      acceptanceSpecs: [],
      paymentTerms: '',
      sellerRequests: [],
      sellerCharges: [],
      buyerRequests: [],
      buyerCharges: [],
      lakalCharges: [],
      status: 'blank',
    };
    return blank;
  }

  blankSellContract(): SellContract {
    var blank: SellContract = {
      contractID: '',
      orderID: '',
      buyerID: '',
      buyerBusinessID: '',
      sellerID: '',
      sellerBusinessID: '',
      commodity: {
        commodityID: '',
        name: '',
        code: '',
        description: '',
        specification: '',
        uom: '',
        testingOptions: [],
        acceptanceOptions: [],
        sortOrder: 0,
      },
      contractQuantity: 0,
      contractPrice: 0,
      contractType: 'sell',
      deliveryDate: new Date(),
      stockpoint: {
        stockpointID: '',
        name: '',
        description: '',
        shortname: '',
        phone: '',
        address: {
          street: '',
          city: '',
          barangay: '',
          province: '',
          zipcode: '',
        },
        coords: {
          longitude: 14.653493,
          latitude: 121.046258,
        },
        services: [],
        commodities: [],
      },
      testingSpecs: [],
      acceptanceSpecs: [],
      paymentTerms: '',
      sellerRequests: [],
      sellerCharges: [],
      buyerRequests: [],
      buyerCharges: [],
      lakalCharges: [],
      status: 'blank',
    };
    return blank;
  }

  async watchAllMyBuyContracts(
    myBusinessID: string
  ): Promise<Observable<any[]>> {
    const allBuyContracts = new Subject<any[]>();
    console.log('watchAllMyBuyContracts', myBusinessID);
    if (this.buyContractsWatcher !== undefined) {
      // can either just cancel the contracts watcher and create a new one
      console.log(this.buyContractsWatcher);
      this.buyContractsWatcher();
    }
    const buyContractsRef = collection(this.firestore, 'buyContracts');
    const q = query(
      buyContractsRef,
      or(
        where('buyerBusinessID', '==', myBusinessID),
        where('sellerBusinessID', '==', myBusinessID)
      )
    );
    const buyContractsSubscription = onSnapshot(q, (buyContracts) => {
      console.log('watchAllMyBuyContracts: new snapshot', buyContracts);
      const data = buyContracts.docs.map((doc) =>
        Object.assign({}, doc.data(), {
          contractDocumentID: doc.id,
          deliveryDate: doc.data()['deliveryDate'].toDate(),
        })
      );
      allBuyContracts.next(data);
      this.buyContracts = data as BuyContract[];
      this.sortBuyContracts();
    });
    this.buyContractsWatcher = buyContractsSubscription;
    this.buyContractsObserver = allBuyContracts.asObservable();
    return this.buyContractsObserver;
  }

  async watchAllMySellContracts(
    myBusinessID: string
  ): Promise<Observable<any[]>> {
    const allSellContracts = new Subject<any[]>();
    if (this.sellContractsWatcher !== undefined) {
      // can either unsubscribe and create a new one or just send back the sellContractsObserver
      console.log('SellContractsWatcher', this.sellContractsWatcher);
      this.sellContractsWatcher();
    }
    console.log('watchAllMySellContracts', myBusinessID);
    const sellContractsRef = collection(this.firestore, 'sellContracts');
    const q = query(
      sellContractsRef,
      or(
        where('buyerBusinessID', '==', myBusinessID),
        where('sellerBusinessID', '==', myBusinessID)
      )
    );
    const sellContractsSubscription = onSnapshot(q, (sellContracts) => {
      console.log('watchAllMySellContracts: new snapshot', sellContracts);
      const data = sellContracts.docs.map((doc) =>
        Object.assign({}, doc.data(), {
          contractDocumentID: doc.id,
          deliveryDate: doc.data()['deliveryDate'].toDate(),
        })
      );
      allSellContracts.next(data);
      this.sellContracts = data as SellContract[];
      this.sortSellContracts();
    });
    this.sellContractsWatcher = sellContractsSubscription;
    this.sellContractsObserver = allSellContracts.asObservable();
    return this.sellContractsObserver;
  }

  sortBuyContracts() {
    console.log('sortBuyContracts', this.buyContracts);
    this.buyContracts.forEach((contract: BuyContract) => {
      if (
        contract.signedBuyer !== undefined &&
        contract.signedSeller === undefined
      ) {
        // do nothing since it is waiting for the seller to sign
      } else if (
        contract.signedBuyer !== undefined &&
        contract.signedSeller !== undefined
      ) {
        switch (contract.status) {
          case 'new':
            break;
          case 'pending':
            this.contractAdd('pending', contract, 'buy');
            break;
          case 'funding':
            this.contractAdd('funding', contract, 'buy');
            break;
          case 'receiving':
            this.contractAdd('receiving', contract, 'buy');
            break;
          case 'payment':
            this.contractAdd('payment', contract, 'buy');
            break;
          case 'delivery':
          case 'shipping':
            this.contractAdd('shipping', contract, 'buy');
            break;
          case 'fulfilled':
          case 'closed':
            this.contractAdd('closed', contract, 'buy');
            break;
          default:
            break;
        }
      }
    });
    this.toggleShowContracts();
  }
  sortSellContracts() {
    console.log('sortSellContracts', this.sellContracts);
    this.sellContracts.forEach((contract: SellContract) => {
      if (
        contract.signedSeller !== undefined &&
        contract.signedBuyer === undefined
      ) {
        // do nothing since it is not yet signed by the buyer
      } else if (
        contract.signedBuyer !== undefined &&
        contract.signedSeller !== undefined
      ) {
        switch (contract.status) {
          case 'new':
            break;
          case 'pending':
            this.contractAdd('pending', contract, 'sell');
            break;
          case 'funding':
            this.contractAdd('funding', contract, 'sell');
            break;
          case 'receiving':
            this.contractAdd('receiving', contract, 'sell');
            break;
          case 'payment':
            this.contractAdd('payment', contract, 'sell');
            break;
          case 'delivery':
          case 'shipping':
            this.contractAdd('shipping', contract, 'sell');
            break;
          case 'fulfilled':
          case 'closed':
            this.contractAdd('closed', contract, 'sell');
            break;
          default:
            break;
        }
      }
    });
    this.toggleShowContracts();
  }

  contractAdd(
    state: string,
    contract: BuyContract | SellContract,
    contractType: string = 'buy'
  ) {
    // get the list

    let bin = this.allContracts[state];
    // check if it is there
    let foundIndex = bin.findIndex((a: any) => {
      return a.contractID == contract.contractID;
    });
    // if it is there, then replace in place
    console.log('contractAdd', foundIndex);
    if (foundIndex >= 0) {
      bin[foundIndex] = contract;
    } else {
      // else push it into the list
      bin.push(contract);
      Object.keys(this.allContracts).forEach((key) => {
        if (key != state) this.contractDelete(key, contract.contractID);
      });
    }
    console.log('contractAdd', state, this.allContracts);
    this.contractAddAbstract(contract, contractType);
  }

  contractAddAbstract(
    contract: BuyContract | SellContract,
    contractType: string = 'buy'
  ) {
    let abstract: IItem = {
      contractID: contract.contractID,
      code: contract.commodity.code,
      qty: contract.contractQuantity.toLocaleString('en-US'),
      price: contract.contractPrice.toLocaleString('en-US', {
        style: 'currency',
        currency: 'PHP',
      }),
      status: contract.status,
      deliveryDate: contract.deliveryDate.toLocaleDateString(),
      stockpoint: contract.stockpoint.name,
      commodity: contract.commodity.name,
      uom: contract.commodity.uom,
      buyerBusinessID: contract.buyerBusinessID,
      sellerBusinessID: contract.sellerBusinessID,
      contractType: contractType,
      contractDocID: contract.contractDocumentID,
    };
    // find the index of the contract in abstracts using contractID
    let foundIndex = this.abstracts.findIndex((a: any) => {
      return a.contractID == contract.contractID;
    });
    // if it is there, then replace in place
    if (foundIndex >= 0) {
      this.abstracts[foundIndex] = abstract;
    } else {
      // else push it into the list
      this.abstracts.push(abstract);
    }
    console.log('contractAddAbstract', this.abstracts);
  }

  toggleShowContracts() {
    this.showContracts = false;
    console.log('toggleShowContracts before', this.showContracts);
    setTimeout(() => {
      this.showContracts = true;
      console.log('toggleShowContracts after', this.showContracts);
    }, 200);
  }

  contractDelete(state: string, contractID: string) {
    // get the list
    let bin = this.allContracts[state];
    // check if it is there
    let foundIndex = bin.findIndex((a: any) => {
      return a.contractID == contractID;
    });
    // if it is there, then remove it
    if (foundIndex >= 0) {
      bin.splice(foundIndex, 1);
    } else {
      // ok, did not find it in this que
    }
  }

  updateContractStatus(
    contractDocID: string,
    contractType: string,
    newStatus: string
  ): Promise<any> {
    console.log('updateContractStatus', contractDocID, contractType, newStatus);
    return new Promise((resolve, reject) => {
      let path = '';
      if (contractType == 'sell') {
        path = 'sellContracts/' + contractDocID;
      } else {
        path = 'buyContracts/' + contractDocID;
      }
      const contractRef = doc(this.firestore, path);
      updateDoc(contractRef, { status: newStatus }).then(
        (result) => {
          console.log('updateContractStatus: updated contract', path, result);
          resolve({ success: true, signed: result });
        },
        (err) => {
          console.log(
            'updateContractStatus: error while updating contract',
            contractDocID,
            err
          );
          reject({
            success: false,
            msg: 'error while updating contract',
            err: err,
          });
        }
      );
    });
  }

  bookPickup(contractInfo: any): Promise<any> {
    console.log('bookPickup', contractInfo);
    return this.updateContractStatus(
      contractInfo.contractDocID,
      contractInfo.contractType,
      'funding'
    );
    // return new Promise((resolve, reject)=>{
    //   const bookingPickup = this.functions.httpsCallable('bookingPickup');
    //   bookingPickup(contractInfo).then((result:any)=>{
    //     console.log("bookingPickup: result", result);
    //     resolve(result);
    //   }, (err:any)=>{
    //     console.log("bookingPickup: error", err);
    //     reject(err);
    //   })
    // })
  }

  bookDelivery(contractInfo: any): Promise<any> {
    console.log('bookDelivery', contractInfo);
    return this.updateContractStatus(
      contractInfo.contractDocID,
      contractInfo.contractType,
      'funding'
    );
    // return new Promise((resolve, reject)=>{
    //   const bookingDelivery = this.functions.httpsCallable('bookingDelivery');
    //   bookingDelivery(contractInfo).then((result:any)=>{
    //     console.log("bookingDelivery: result", result);
    //     resolve(result);
    //   }, (err:any)=>{
    //     console.log("bookingDelivery: error", err);
    //     reject(err);
    //   })
  }

  requestPreFund(contractInfo: any): Promise<any> {
    console.log('requestPreFund', contractInfo);
    return this.updateContractStatus(
      contractInfo.contractDocID,
      contractInfo.contractType,
      'receiving'
    );
    // return new Promise((resolve, reject)=>{
    //   const requestPreFund = this.functions.httpsCallable('requestPreFund');
    //   requestPreFund(contractInfo).then((result:any)=>{
    //     console.log("requestPreFund: result", result);
    //     resolve(result);
    //   }, (err:any)=>{
    //     console.log("requestPreFund: error", err);
    //     reject(err);
    //   })
    // })
  }

  performReceiving(contractInfo: any): Promise<any> {
    console.log('performReceiving', contractInfo);
    return this.updateContractStatus(
      contractInfo.contractDocID,
      contractInfo.contractType,
      'payment'
    );
    // return new Promise((resolve, reject)=>{
    //   const performReceiving = this.functions.httpsCallable('performReceiving');
    //   performReceiving(contractInfo).then((result:any)=>{
    //     console.log("performReceiving: result", result);
    //     resolve(result);
    //   }, (err:any)=>{
    //     console.log("performReceiving: error", err);
    //     reject(err);
    //   })
    // })
  }
  transferPayment(contractInfo: any): Promise<any> {
    console.log('transferPayment', contractInfo);
    return this.updateContractStatus(
      contractInfo.contractDocID,
      contractInfo.contractType,
      'delivery'
    );
    // return new Promise((resolve, reject)=>{
    //   const transferPayment = this.functions.httpsCallable('transferPayment');
    //   transferPayment(contractInfo).then((result:any)=>{
    //     console.log("transferPayment: result", result);
    //     resolve(result);
    //   }, (err:any)=>{
    //     console.log("transferPayment: error", err);
    //     reject(err);
    //   })
    // })
  }

  performShipping(contractInfo: any): Promise<any> {
    console.log('performShipping', contractInfo);
    return this.updateContractStatus(
      contractInfo.contractDocID,
      contractInfo.contractType,
      'closed'
    );

    // return new Promise((resolve, reject)=>{
    //   const performShipping = this.functions.httpsCallable('performShipping');
    //   performShipping(contractInfo).then((result:any)=>{
    //     console.log("performShipping: result", result);
    //     resolve(result);
    //   }, (err:any)=>{
    //     console.log("performShipping: error", err);
    //     reject(err);
    //   })
    // })
  }
}
